var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{attrs:{"id":"footer"}},[_c('div',{staticClass:"footer-top"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-lg-1 col-md-6 footer-links"}),_c('div',{staticClass:"col-lg-4 col-md-6 footer-links"},[_c('h4',[_vm._v("Our Services")]),_c('ul',{staticClass:"pt-3"},[_c('li',[_c('i',{staticClass:"lni lni-whatsapp"}),_vm._v("  "),_c('a',{attrs:{"href":'https://api.whatsapp.com/send?phone=62813160009990&text= Hai min, saya mau tanya untuk paket sewa box seserahan dan undangan digitalnya nih. Terima kasih!',"target":"_blank"}},[_vm._v("0813160009990")])]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])])])])]),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-7 col-md-6 footer-contact"},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-1"},[_c('a',{attrs:{"href":"index.html"}},[_c('img',{staticStyle:{"width":"50px","height":"50px"},attrs:{"src":"assets/img/logo.png","alt":""}})])]),_c('div',{staticClass:"col-md-11 pt-2"},[_c('h3',{staticStyle:{"font-family":"'Oleo Script', Sans-serif"}},[_vm._v("It's Your Day")])])]),_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" It's Your Day adalah layanan yang hadir sebagai solusi untuk kebutuhan hari istimewa Anda. Kami menawarkan jasa penyewaan box seserahan (termasuk hiasan seserahan) dan pembuatan undangan digital untuk melengkapi momen istimewa Anda. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"lni lni-envelope"}),_vm._v("  "),_c('a',{attrs:{"href":"jvascript:void(0)"}},[_vm._v("itsyourday.business@gmail.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"bx bxl-instagram"}),_vm._v("  "),_c('a',{attrs:{"href":"https://instagram.com/itsyourday_seserahan/"}},[_vm._v("@itsyourday_seserahan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"bx bxl-instagram"}),_vm._v("  "),_c('a',{attrs:{"href":"https://www.instagram.com/itsyourday_digitalinvitation/"}},[_vm._v("@itsyourday_digitalinvitation")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"bx bxl-instagram"}),_vm._v("  "),_c('a',{attrs:{"href":"https://www.instagram.com/itsyourday_happinessbox/"}},[_vm._v("@itsyourday_happinessbox")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"bx bxl-instagram"}),_vm._v("  "),_c('a',{attrs:{"href":"https://www.instagram.com/itsyourday_bridesmaid/"}},[_vm._v("@itsyourday_bridesmaid")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container d-md-flex py-4"},[_c('div',{staticClass:"me-md-auto text-center text-md-start"},[_c('div',{staticClass:"copyright"},[_vm._v(" Developed by "),_c('strong',[_vm._v("It's Your Day")])]),_c('div',{staticClass:"credits"},[_vm._v(" Designed by "),_c('a',{attrs:{"href":"https://bootstrapmade.com/"}},[_vm._v("BootstrapMade")])])]),_c('div',{staticClass:"social-links text-center text-md-right pt-3 pt-md-0"})])
}]

export { render, staticRenderFns }