var render = function render(){var _vm=this,_c=_vm._self._c;return (this.$route.path == '/')?_c('header',{staticClass:"fixed-top header-transparent",attrs:{"id":"header"}},[_c('div',{staticClass:"container d-flex align-items-center justify-content-between"},[_vm._m(0),_c('nav',{staticClass:"navbar",attrs:{"id":"navbar"}},[_c('ul',[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('li',[_c('router-link',{staticClass:"nav-link scrollto",attrs:{"to":{name:'Seserahan'}}},[_vm._v(" Galeri ")])],1),_c('li',[_c('router-link',{staticClass:"nav-link scrollto",attrs:{"to":{name:'SnK'}}},[_vm._v(" Syarat & Ketentuan ")])],1)]),_c('i',{staticClass:"bi bi-list mobile-nav-toggle"})])])]):(this.$route.path == '/SnK')?_c('header',{staticClass:"fixed-top header-transparent-2",attrs:{"id":"header"}},[_c('div',{staticClass:"container d-flex align-items-center justify-content-between"},[_vm._m(5),_c('nav',{staticClass:"navbar",attrs:{"id":"navbar"}},[_c('ul',[_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_c('li',[_c('router-link',{staticClass:"nav-link scrollto",attrs:{"to":{name:'Seserahan'}}},[_vm._v(" Galeri ")])],1),_c('li',[_c('router-link',{staticClass:"nav-link scrollto active",attrs:{"to":{name:'SnK'}}},[_vm._v(" Syarat & Ketentuan ")])],1)]),_c('i',{staticClass:"bi bi-list mobile-nav-toggle"})])])]):_c('header',{staticClass:"fixed-top header-transparent-2",attrs:{"id":"header"}},[_c('div',{staticClass:"container d-flex align-items-center justify-content-between"},[_vm._m(10),_c('nav',{staticClass:"navbar",attrs:{"id":"navbar"}},[_c('ul',[_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_c('li',[_c('router-link',{staticClass:"nav-link scrollto",attrs:{"to":{name:'Seserahan'}}},[_vm._v(" Galeri ")])],1),_c('li',[_c('router-link',{staticClass:"nav-link scrollto",attrs:{"to":{name:'SnK'}}},[_vm._v(" Syarat & Ketentuan ")])],1)]),_c('i',{staticClass:"bi bi-list mobile-nav-toggle"})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"logo"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"assets/img/logo.png","alt":""}}),_vm._v("  "),_c('a',{staticStyle:{"font-family":"'Oleo Script', Sans-serif","font-size":"20px"},attrs:{"href":"/"}},[_vm._v("It's Your Day")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"nav-link scrollto active",attrs:{"href":"#hero"}},[_vm._v("Home")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"nav-link scrollto",attrs:{"href":"#product"}},[_vm._v("Produk")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"nav-link scrollto",attrs:{"href":"#services"}},[_vm._v("Keunggulan Produk")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"nav-link scrollto",attrs:{"href":"#pricing"}},[_vm._v("Paket Harga")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"logo"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"assets/img/logo.png","alt":""}}),_vm._v("  "),_c('a',{staticStyle:{"font-family":"'Oleo Script', Sans-serif"},attrs:{"href":"/"}},[_vm._v("It's Your Day")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"nav-link scrollto",attrs:{"href":"/"}},[_vm._v("Home")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"nav-link scrollto",attrs:{"href":"/#product"}},[_vm._v("Produk")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"nav-link scrollto",attrs:{"href":"/#services"}},[_vm._v("Keunggulan Produk")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"nav-link scrollto",attrs:{"href":"/#pricing"}},[_vm._v("Paket Harga")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"logo"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"assets/img/logo.png","alt":""}}),_vm._v("  "),_c('a',{staticStyle:{"font-family":"'Oleo Script', Sans-serif"},attrs:{"href":"/"}},[_vm._v("It's Your Day")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"nav-link scrollto",attrs:{"href":"/"}},[_vm._v("Home")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"nav-link scrollto",attrs:{"href":"/#product"}},[_vm._v("Produk")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"nav-link scrollto",attrs:{"href":"/#services"}},[_vm._v("Keunggulan Produk")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"nav-link scrollto",attrs:{"href":"/#pricing"}},[_vm._v("Paket Harga")])])
}]

export { render, staticRenderFns }