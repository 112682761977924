<template>
    <div class="home">        
        <main id="main">

            <!-- ======= Breadcrumbs ======= -->
            <section class="breadcrumbs">
                <div class="container">

                    <div class="d-flex justify-content-between align-items-center">
                    <h2>Our Gallery</h2>
                    <ol>
                        <li><a href="/">Home</a></li>
                        <li>Our Gallery</li>
                    </ol>
                    </div>

                </div>
            </section><!-- End Breadcrumbs -->

            <!-- ======= Portfolio Section ======= -->
            <section id="pricing" class="pricing">
                <div class="container">

                <div class="section-title">
                    <h2>The best look to complete your special moment.</h2>                    
                </div>

                <div class="col-md-12">
                    <ul id="features-filter" class="nav nav-pills mb-2 justify-content-center" role="tab-list">
                        <li class="nav-item">
                            <a class="nav-link active" data-bs-toggle="pill" href="#foto" role="tab" >Foto</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="pill" href="#video" role="tab">Video</a>
                        </li>
                    </ul>
                </div>

                <div class="row justify-content-center">
                    <div class="tab-content" >
                        
                        <!-- FOTO SECTION -->
                        <div class="tab-pane fade show active" id="foto" role="tabpanel" aria-labelledby="foto-tab">
                            <div class="col-md-12">
                  
                                <ul id="features-filter" class="nav nav-pills justify-content-center" role="tab-list">
                                    <li class="nav-item">
                                        <a class="nav-link active" data-bs-toggle="pill" href="#seserahan" role="tab" >Seserahan</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="pill" href="#mahar" role="tab">Mahar Frame</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link"  data-bs-toggle="pill" href="#ringbox" role="tab">Ring Box</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link"  data-bs-toggle="pill" href="#jewelry" role="tab">Jewelry Box</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-content">
                                <div id="seserahan" class="tab-pane fade show active">
                                    <ul id="features-filter" class="nav nav-pills justify-content-center" role="tab-list">
                                        <li class="nav-item">
                                            <a class="nav-link active" data-bs-toggle="pill" href="#seserahan-acrylic" role="tab">Acrylic</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" data-bs-toggle="pill" href="#seserahan-crystal" role="tab">Crystal</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" data-bs-toggle="pill" href="#seserahan-pearl" role="tab">Pearl</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" data-bs-toggle="pill" href="#seserahan-terarium" role="tab">Terarium</a>
                                        </li>
                                    </ul>

                                    <div class="tab-content">
                                        <div id="seserahan-acrylic" class="tab-pane fade show active">
                                            <div class="row justify-content-center">
                                                <div v-for="seserahan in seserahan_foto" :key="seserahan.id" v-if="seserahan.category.toLowerCase() === 'seserahan' && seserahan.sub_category.toLowerCase() === 'acrylic'" class="col-lg-3 col-md-6 mt-2 text-center">
                                                    <img v-img:foto-acrylic :src="'https://admin.itsyourdayofficial.com/gallery_seserahan/' + seserahan.filename" style="border-radius: 5px; height: 200px;" class="img-fluid img-thumbnail" alt="...">
                                                </div>
                                            </div>
                                        </div>
                                        <div id="seserahan-crystal" class="tab-pane fade">
                                            <div class="row justify-content-center">
                                                <div v-for="seserahan in seserahan_foto" :key="seserahan.id" v-if="seserahan.category.toLowerCase() === 'seserahan' && seserahan.sub_category.toLowerCase() === 'crystal'" class="col-lg-3 col-md-6 mt-2 text-center">
                                                    <img v-img:foto-crystal :src="'https://admin.itsyourdayofficial.com/gallery_seserahan/' + seserahan.filename" style="border-radius: 5px; height: 200px;" class="img-fluid img-thumbnail" alt="...">
                                                </div>
                                            </div>
                                        </div>
                                        <div id="seserahan-pearl" class="tab-pane fade">
                                            <div class="row justify-content-center">
                                                <div v-for="seserahan in seserahan_foto" :key="seserahan.id" v-if="seserahan.category.toLowerCase() === 'seserahan' && seserahan.sub_category.toLowerCase() === 'pearl'" class="col-lg-3 col-md-6 mt-2 text-center">
                                                    <img v-img:foto-pearl :src="'https://admin.itsyourdayofficial.com/gallery_seserahan/' + seserahan.filename" style="border-radius: 5px; height: 200px;" class="img-fluid img-thumbnail" alt="...">
                                                </div>
                                            </div>
                                        </div>
                                        <div id="seserahan-terarium" class="tab-pane fade">
                                            <div class="row justify-content-center">
                                                <div v-for="seserahan in seserahan_foto" :key="seserahan.id" v-if="seserahan.category.toLowerCase() === 'seserahan' && seserahan.sub_category.toLowerCase() === 'terarium'" class="col-lg-3 col-md-6 mt-2 text-center">
                                                    <img v-img:foto-terarium :src="'https://admin.itsyourdayofficial.com/gallery_seserahan/' + seserahan.filename" style="border-radius: 5px; height: 200px;" class="img-fluid img-thumbnail" alt="...">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div id="mahar" class="tab-pane fade ">
                                    <div class="row justify-content-center">
                                        <div v-for="seserahan in seserahan_foto" :key="seserahan.id" v-if="seserahan.category.toLowerCase() === 'mahar_frame'" class="col-lg-3 col-md-6 mt-2 text-center">
                                            <img v-img:foto :src="'https://admin.itsyourdayofficial.com/gallery_seserahan/' + seserahan.filename" style="border-radius: 5px; height: 200px;" class="img-fluid img-thumbnail" alt="...">
                                        </div>
                                    </div>
                                </div>
                                <div id="ringbox" class="tab-pane fade">
                                    <div class="row justify-content-center">
                                        <div v-for="seserahan in seserahan_foto" :key="seserahan.id" v-if="seserahan.category.toLowerCase() === 'ring_box'" class="col-lg-3 col-md-6 mt-2 text-center">
                                            <img v-img:foto :src="'https://admin.itsyourdayofficial.com/gallery_seserahan/' + seserahan.filename" style="border-radius: 5px; height: 200px;" class="img-fluid img-thumbnail" alt="...">
                                        </div>
                                    </div>
                                </div>
                                <div id="jewelry" class="tab-pane fade">
                                    <div class="row justify-content-center">
                                        <div v-for="seserahan in seserahan_foto" :key="seserahan.id" v-if="seserahan.category.toLowerCase() === 'jewelry_box'" class="col-lg-3 col-md-6 mt-2 text-center">
                                            <img v-img:foto :src="'https://admin.itsyourdayofficial.com/gallery_seserahan/' + seserahan.filename" style="border-radius: 5px; height: 200px;" class="img-fluid img-thumbnail" alt="...">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <!-- VIDEO SECTION -->
                        <div class="tab-pane fade" id="video" role="tabpanel" aria-labelledby="video-tab">
                            <div class="row justify-content-center">
                                <div v-for="seserahan in seserahan_video" :key="seserahan.id" class="col-lg-3 col-md-6 mt-2 text-center">
                                    <video ref="video" @loadedmetadata="onLoadedMetadata" preload="metadata" width="100%" height="280px;" style="border-radius: 5px; padding: 0.25rem; background-color: #fff; border: 1px solid #dee2e6;" controls>
                                        <source :src="'https://admin.itsyourdayofficial.com/gallery_seserahan/' + seserahan.filename" type="video/mp4">
                                    </video>
                                    <img v-if="thumbnail" :src="thumbnail" alt="Video thumbnail">
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </div>

                </div>
            </section>
            <!-- End Portfolio Section -->

        </main><!-- End #main -->
    </div>
    
</template>

<script>
export default {
  props: ['templates', 'seserahan_foto', 'seserahan_video'],
  data() {
    return {
      thumbnail: null
    }
  },
  methods: {
    onLoadedMetadata() {
      // Ambil bingkai pertama dari video
      const canvas = document.createElement('canvas');
      canvas.width = this.$refs.video.videoWidth;
      canvas.height = this.$refs.video.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(this.$refs.video, 0, 0, canvas.width, canvas.height);
      const dataURL = canvas.toDataURL();
      this.thumbnail = dataURL;
    },
    activateTabBasedOnHash() {
      const hash = window.location.hash;
      if (hash) {
        const tabTrigger = document.querySelector(`a[href="${hash}"]`);
        if (tabTrigger) {
          tabTrigger.click();
        }
      }
    }
  },
  mounted() {
    // Aktifkan tab berdasarkan hash URL setiap kali komponen di-mount
    this.$nextTick(() => {
      this.activateTabBasedOnHash();
    });
  },
  beforeDestroy() {
    // Hapus event listener saat komponen dihancurkan
    document.removeEventListener('DOMContentLoaded', this.activateTabBasedOnHash);
  }
}
</script>

<style scoped>
.nav-pills .nav-link.active,
.nav-pills .nav-link:hover,
.nav-pills .show > .nav-link {
  background: #BCA37F;
  color: #ffffff;
}

.nav-pills .nav-link {
  cursor: pointer;
  display: inline-block;
  padding: 8px 18px 10px 18px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #444444;
  margin: 0 3px 10px 3px;
  transition: all ease-in-out 0.3s;
  background: #f4f5f8;
  border-radius: 50px;
}
</style>