<template>
    <div class="home">        
        <main id="main">

            <!-- ======= Breadcrumbs ======= -->
            <section class="breadcrumbs">
                <div class="container">

                    <div class="d-flex justify-content-between align-items-center">
                    <h2>Claim Voucher</h2>
                    <ol>
                        <li><a href="/">Home</a></li>
                        <li>Claim Voucher</li>
                    </ol>
                    </div>

                </div>
            </section><!-- End Breadcrumbs -->

            <!-- ======= Claim Voucher Section ======= -->
            <section class="pricing">
                <div class="container">

                    <div class="section-title">
                        <h2>Claim Voucher</h2>         
                    </div>
                    <div class="row justify-content-center" v-if="getVoucher && getVoucher.status === 1">
                        <div class="col-lg-12 col-md-12 mt-2">
                            <img :src="'https://admin.itsyourdayofficial.com/image_banner/' + getVoucher.image_banner" class="img-fluid" alt="" style="">
                        </div>  
                        
                        <div class="col-lg-12 col-md-12 mt-2 " style="padding-top: 10px;">
                            <h2 class="text-center" style="padding-bottom: 30px;">{{ getVoucher.title }}</h2>
                        </div>
                        <div class="col-lg-8 col-md-8 mt-2 " >
                            <p class="text-justify" v-html="getVoucher.description"></p>
                        </div>

                        <div class="col-lg-8 col-md-8 mt-5 text-center" >
                            <button class="btn btn-md" data-toggle="modal" data-target="#claimVoucher" style="background-color: #BCA37F; color: white;">Claim Voucher</button>
                        </div>
                    </div>
                </div>
            </section>
            <!-- End Claim Voucher Section -->

        </main><!-- End #main -->
        <div class="modal fade" id="claimVoucher" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body" v-if="!claimed">
                        <div class="text-right" style="text-align: right;">                            
                            <a data-dismiss="modal" aria-label="Close" type="button">
                                <i class="bi bi-x-circle"></i>
                            </a>
                        </div>
                        <form style="padding:15px;">
                            <div class="form-group" >
                                <label for="formGroupExampleInput">Nama Lengkap</label>
                                <input @input="resetErrors" v-model="claim.fullname" type="text" class="form-control" id="formGroupExampleInput" placeholder="Ketik nama lengkap anda" autocomplete="off">
                            </div>
                            <span v-if="errors.fullname"
                                style="color:white; margin-bottom:10px; background:rgb(169, 157, 135); border-radius: 50px; padding-left: 10px; padding-right: 10px;"
                                class="badge badge-danger rounded-pill">
                                Harap isi nama lengkap anda
                            </span>
                            <span v-else-if="errors === 'Nama atau nomor anda sudah terdaftar'"
                                style="color:white; margin-bottom:10px; background:rgb(169, 157, 135); border-radius: 50px; padding-left: 10px; padding-right: 10px;"
                                class="badge badge-danger rounded-pill">
                                Nama atau nomor anda sudah terdaftar
                            </span>
                            <div class="form-group" style="padding-top: 10px;">
                                <label for="formGroupExampleInput2">Nomor Whatsapp</label>
                                <input v-model="claim.wa_number" @input="handleInput" type="text" class="form-control" id="formGroupExampleInput2" placeholder="08xxxxxxxxxx" autocomplete="off">
                            </div>
                            <span v-if="errors.wa_number"
                                style="color:white; margin-bottom:10px; background:rgb(169, 157, 135); border-radius: 50px; padding-left: 10px; padding-right: 10px;"
                                class="badge badge-danger rounded-pill">
                                Harap isi nomor whatsapp anda
                            </span>
                            <span v-else-if="errors === 'Nama atau nomor anda sudah terdaftar'"
                                style="color:white; margin-bottom:10px; background:rgb(169, 157, 135); border-radius: 50px; padding-left: 10px; padding-right: 10px;"
                                class="badge badge-danger rounded-pill">
                                Nama atau nomor anda sudah terdaftar
                            </span>
                            <span v-else-if="validationWA"
                                style="color:white; margin-bottom:10px; background:rgb(169, 157, 135); border-radius: 50px; padding-left: 10px; padding-right: 10px;"
                                class="badge badge-danger rounded-pill">
                                {{validationWA}}
                            </span>
                            <div class="form-group" style="padding-top: 10px;">   
                                <label for="formGroupExampleInput">Promo Code</label>                             
                                <div class="input-group ">
                                    <input @input="resetErrors" v-model="claim.voucher_code" type="text" class="form-control" placeholder="Ketik promo code disini" aria-label="Recipient's username" aria-describedby="basic-addon2">
                                    <div class="input-group-append">
                                        <button @click.prevent="post" class="btn" type="button" style="background-color: #BCA37F; color: white;">Claim</button>
                                    </div>
                                </div>
                                <span v-if="errors.voucher_code"
                                    style="color:white; margin-bottom:10px; background:rgb(169, 157, 135); border-radius: 50px; padding-left: 10px; padding-right: 10px;"
                                    class="badge badge-danger rounded-pill">
                                    Harap isi voucher code
                                </span>
                                <span v-else-if="errors === 'Voucher code salah'"
                                    style="color:white; margin-bottom:10px; background:rgb(169, 157, 135); border-radius: 50px; padding-left: 10px; padding-right: 10px;"
                                    class="badge badge-danger rounded-pill">
                                    Voucher code salah
                                </span>
                            </div>
                        </form>
                    </div>
                    <div class="modal-body" v-else>
                        <div class="row justify-content-center">
                            <div class="col-lg-12 col-md-12 text-right" style="text-align: right;">                            
                                <a data-dismiss="modal" aria-label="Close" type="button">
                                    <i class="bi bi-x-circle"></i>
                                </a>
                            </div>
                            <div class="col-lg-10 col-md-10 ">                            
                                <p style="padding-top: 0px; padding: 15px;">Yeay, kamu berhasil claim voucher!&#127881; Tunggu sampai admin kami menghubungi kamu ya &#128522</p>
                            </div>  
                        </div>                      
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['getVoucher'],
    data() {
        return{
            errors: {},
            claim: {
                fullname: '',
                wa_number: '',
                voucher_code: '',
            },
            response_claim: {},
            claimed: false,
            validationWA: '',
        }
    },
    methods: {
        post() {
            if (this.validationWA) {
                return; // Menghentikan post jika validasi tidak memenuhi ketentuan
            }

            this.$api.post('claim', this.claim)
                .then(response => {
                    this.response_claim = response.data.message
                    this.claim.fullname = '';
                    this.claim.wa_number = '';
                    this.claim.voucher_code = '';
                    this.claimed = true;
                    console.log(response);
                })
                .catch(error => {
                    if (error.response.status === 400) {
                        this.errors = error.response.data.error;
                        console.log(error);
                        console.log(this.errors);
                    }
                });
        },
        clearErrors() {
            this.errors = {};
            this.claim.fullname = '';
            this.claim.wa_number = '';
            this.claim.voucher_code = '';
            this.claimed = false;
            this.validationWA = '';
        },
        resetErrors() {
            if (this.errors === 'Nama atau nomor anda sudah terdaftar'  || this.errors === 'Voucher code salah') {
                this.errors = {};
            } else {
                this.errors.fullname = null;
            }
        },
        validateInput() {
            const regex = /^[0-9]{1,13}$/;
            if (!regex.test(this.claim.wa_number)) {
                this.claim.wa_number = this.claim.wa_number.slice(0, 13); 
                this.validationWA = 'Nomor WhatsApp harus terdiri dari angka dan panjang 1-13 digit';
            } else if (this.claim.wa_number.length >= 2 && !this.claim.wa_number.startsWith('08')) {
                this.validationWA = 'Nomor WhatsApp harus diawali dengan angka 08';
            } else {
                this.validationWA = '';
                this.errors.wa_number = null; // Clear error
            }
        },
        handleInput() {
            this.resetErrors();
            this.validateInput();
        }
    },
    mounted() {
        $('#claimVoucher').on('hidden.bs.modal', this.clearErrors);
    }
}
</script>

<style scoped>
.nav-pills .nav-link.active,
.nav-pills .nav-link:hover,
.nav-pills .show > .nav-link {
  background: #BCA37F;
  color: #ffffff;
}

.nav-pills .nav-link {
  cursor: pointer;
  display: inline-block;
  padding: 8px 18px 10px 18px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #444444;
  margin: 0 3px 10px 3px;
  transition: all ease-in-out 0.3s;
  background: #f4f5f8;
  border-radius: 50px;
}
</style>